import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import LayoutServices from "../../components/layout/layout-services"
import { GetInTouch } from "../../components/services/get-in-touch"
import { ServicesScanFacility } from "../../components/services/services-scan-facility"
import { ServicesScanGallery } from "../../components/services/services-scan-gallery"
import { ServicesScanHero } from "../../components/services/services-scan-hero"
import { ServicesScanProcessing } from "../../components/services/services-scan-processing"
import { ServicesScanScanning } from "../../components/services/services-scan-scanning"

const ServicesScanPage = () => {
  const data = useStaticQuery(graphql`
    {
      strapi {
        servicesScanPage {
          ScanHeroTitle
          ScanHeroLead
          ScanHeroButton
          ScanHeroBg {
            url
            imageFile {
              childImageSharp {
                fluid(maxWidth: 3000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          ScanningTitle
          ScanningLeftImageModel {
            url
            imageFile {
              publicURL
            }
          }
          ScanningLeftImageFinished {
            url
            imageFile {
              publicURL
            }
          }
          ScanningDescriptionRight
          ScanningDescriptionLeft
          ScanningRightImageModel {
            url
            imageFile {
              publicURL
            }
          }
          ScanningRightImageFinished {
            url
            imageFile {
              publicURL
            }
          }
          LocationTitle
          LocationLeftImage {
            url
            imageFile {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          LocationDescriptionListFirst
          FacilityTitle
          FacilityLead
          FacilityDegreeNumber
          FacilityDegreeDescription
          FacilityCamerasNumber
          FacilityCamerasDescription
          FacilityNiconNumber
          FacilityNiconDescription
          FacilityBg {
            url
            imageFile {
              childImageSharp {
                fluid(maxWidth: 3000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          ScanGallery {
            GalleryItem {
              url
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          ProcessingTitle
          ProcessingLead
          ProcessingRawTitle
          ProcessingRawLead
          ProcessingBasicUpLead
          ProcessingBasicTitle
          ProcessingAdvancedTitle
          ProcessingAdvancedLead
          ProcessingRetopoTitle
          ProcessingRetopoLead
        }
        servicesMocapPage {
          CreateTitle
          CreateLead
          CreateButton
          CreateBg {
            url
            imageFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <LayoutServices>
      <ServicesScanHero hero={data.strapi.servicesScanPage} />
      <ServicesScanScanning scanning={data.strapi.servicesScanPage} />
      <ServicesScanFacility facility={data.strapi.servicesScanPage} />
      <ServicesScanGallery gallery={data.strapi.servicesScanPage} />
      <ServicesScanProcessing processing={data.strapi.servicesScanPage} />
      <GetInTouch create={data.strapi.servicesMocapPage} />
    </LayoutServices>
  )
}

export default ServicesScanPage
