import { Box, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Img from "gatsby-image"
import React from "react"
import ScrollTo from "../utils/scroll-to"
import Down from "./down"
import ScanIcon from "./scan-icon"

const useStyles = makeStyles(theme => ({
  title: {
    width: "100%",
    textAlign: "center",
    margin: "48px 0",
    [theme.breakpoints.down("sm")]: {
      margin: "24px 0",
    },
  },
  container: {
    position: "relative",
    height: "90vh",
  },
  content: {
    maxWidth: "800px",
    textAlign: "center",
    margin: "20px 0",
    color: "#fff",
    filter: "drop-shadow(4px 4px 8px #000b)",
  },
  button: {
    textDecoration: "none",
    textTransform: "uppercase",
    color: "#F26522",
    fontSize: "16px",
    marginTop: "48px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0px",
    },
    fontWeight: "700",
    cursor: "pointer",
  },
  contentContainer: {
    position: "absolute",
    margin: "24px 0",
    padding: "8px",
    top: "0",
    left: "0",
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: "3",
    background:
      "linear-gradient(180deg, rgba(0, 0, 0, 0.5) 70%, rgba(0, 0, 0, 0.7) 80%,  #000000 95%)",
  },
}))

export const ServicesScanHero = ({ hero }) => {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <Box className={classes.contentContainer}>
        <Box
          maxWidth="820px"
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          style={{ filter: "drop-shadow(4px 4px 6px #000)" }}
        >
          <Box style={{ marginBottom: "20px" }}>
            <ScanIcon />
          </Box>
          <Typography variant="h1" align="center" style={{ text: "uppercase" }}>
            {hero.ScanHeroTitle}
          </Typography>
          <Typography className={classes.content} variant="body1">
            {hero.ScanHeroLead}
          </Typography>
          <ScrollTo className={classes.button} to="scanning">
            {hero.ScanHeroButton} <Down />
          </ScrollTo>
        </Box>
      </Box>
      <Img
        style={{
          height: "90vh",
          width: "100vw",
          objectFit: "cover",
          marginBottom: "50px",
        }}
        fluid={hero.ScanHeroBg.imageFile.childImageSharp.fluid}
      />
    </Box>
  )
}
