import { Grid, Box, makeStyles } from "@material-ui/core"
import React from "react"
import Img from "gatsby-image"

const useStyles = makeStyles(theme => ({
  workItem: {
    [theme.breakpoints.down("xl")]: {
      height: "14.0625vw",
    },
    [theme.breakpoints.down("lg")]: {
      height: "18.75vw",
    },
    [theme.breakpoints.down("md")]: {
      height: "25vw",
    },
    [theme.breakpoints.down("sm")]: {
      height: "30vw",
    },
  },
  container: {
    maxWidth: "1382px",
    marginLeft: "auto",
    marginRight: "auto",
  },

  content: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    "& h2": {
      transition: "all 800ms ease !important",
    },
    "&:hover": {
      "& h2": {
        letterSpacing: "3px",
      },
    },
    transition: "all 800ms ease",
  },
}))

export const ServicesScanGallery = ({ gallery }) => {
  const classes = useStyles()
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      width="100%"
    >
      <Grid style={{ marginBottom: "64px" }} container>
        {gallery.ScanGallery.map(({ GalleryItem, index }) => (
          <Grid
            key={index}
            item
            xs={6}
            md={6}
            lg={4}
            // xl={3}
            className={classes.workItem}
          >
            <Img
              className={classes.content}
              fluid={GalleryItem.imageFile.childImageSharp.fluid}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}
