import { Box, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Img from "gatsby-image"
import React from "react"

const useStyles = makeStyles(theme => ({
  title: {
    width: "100%",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      margin: "24px 0",
    },
  },
  container: {
    position: "relative",
  },
  content: {
    maxWidth: "560px",
    textAlign: "center",
    margin: "30px 0",
  },
  box: {
    width: "710px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      maxWidth: "710px",
    },
    maxHeight: "447",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backdropFilter: "blur(10px)",
    backgroundColor: "rgba(30, 32, 33, 0.9)",
    padding: "20px",
  },
  bg: {
    maxHeight: "775px",
    filter: "brightness(0.7)",
    [theme.breakpoints.down("md")]: { minHeight: "650px" },
    [theme.breakpoints.down("xs")]: { minHeight: "1000px" },
    objectFit: "cover",
  },
  line: {
    color: "#F26522",
    textTransform: "uppercase",
    maxWidth: "120px",
  },
  dataContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "190px",
    margin: "10px",
  },
}))

export const ServicesScanFacility = ({ facility }) => {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <Img
        className={classes.bg}
        fluid={facility.FacilityBg.imageFile.childImageSharp.fluid}
      />
      <Box
        position="absolute"
        px={2}
        top={0}
        left={0}
        height="100%"
        width="100%"
        overflow="hidden"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box className={classes.box}>
          <Typography
            variant="h2"
            align="center"
            style={{
              text: "uppercase",
              color: "#F26522",
              marginTop: "20px",
              fontSize: "34px",
            }}
          >
            {facility.FacilityTitle}
          </Typography>
          <Typography className={classes.content} variant="body1">
            {facility.FacilityLead}
          </Typography>
          <Box
            style={{
              textAlign: "center",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              marginTop: "10px",
              marginBottom: "30px",
            }}
          >
            <Box className={classes.dataContainer}>
              <Typography variant="h1" align="center">
                {facility.FacilityDegreeNumber}
              </Typography>
              <Typography variant="caption" className={classes.line}>
                {facility.FacilityDegreeDescription}
              </Typography>
            </Box>
            <Box className={classes.dataContainer}>
              <Typography variant="h1" align="center">
                {facility.FacilityCamerasNumber}
              </Typography>
              <Typography variant="caption" className={classes.line}>
                {facility.FacilityCamerasDescription}
              </Typography>
            </Box>
            <Box className={classes.dataContainer}>
              <Typography
                variant="h1"
                align="center"
                style={{
                  textTransform: "none",
                }}
              >
                {facility.FacilityNiconNumber}
              </Typography>
              <Typography variant="caption" className={classes.line}>
                {facility.FacilityNiconDescription}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
