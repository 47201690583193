import { Typography, Box, makeStyles } from "@material-ui/core"
import React from "react"
import Rawscan from "../../images/scan/scan1.png"
import Cleanup from "../../images/scan/scan2.png"
import Advanced from "../../images/scan/scan3.png"

const useStyles = makeStyles(theme => ({
  mainContainer: {
    marginBottom: "96px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0 48px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "0px",
      padding: "0 24px",
    },
  },
  title: {
    color: "#F26522",
    paddingTop: "20px",
    maxWidth: "1440px",
    margin: "auto",
    width: "100%",
    paddingLeft: "0px",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "16px",
    },
  },
  container: {
    marginTop: "70px",
    display: "flex",
    gap: "96px",
    backgroundColor: "#000",
    maxWidth: "1440px",
    [theme.breakpoints.down("md")]: {
      gap: "48px",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  box: {
    paddingLeft: "16px",
    paddingRight: "16px",
    top: "0",
    left: "0",
    height: "100%",
    width: "100%",
    overflow: "hidden",
    display: "flex",
    backgroundColor: "#000",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: "32px",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    width: "100%",
    [theme.breakpoints.down("xs")]: { maxWidth: "initial" },
    margin: "32px 0",
  },
  processTitle: {
    color: "#F26522",
    textTransform: "uppercase",
    fontSize: "24px",
    marginBottom: "10px",
    marginTop: "32px",
    filter: "drop-shadow(2px 2px 4px #000)",
  },
  processParagraph: {
    filter: "drop-shadow(2px 2px 4px #000)",
    maxWidth: "500px",
  },
  scanImage: {
    width: "100%",
    [theme.breakpoints.down("sm")]: { width: "60%" },
  },
}))

export const ServicesScanProcessing = ({ processing }) => {
  const classes = useStyles()
  const steps = [
    {
      title: processing.ProcessingRawTitle,
      lead: processing.ProcessingRawLead,
      image: Rawscan,
    },
    {
      title: processing.ProcessingBasicTitle,
      lead: processing.ProcessingBasicUpLead,
      image: Cleanup,
    },
    {
      title: processing.ProcessingRetopoTitle,
      lead: processing.ProcessingRetopoLead,
      image: Advanced,
    },
  ]

  return (
    <Box className={classes.mainContainer}>
      <Box width="100%" display="flex" justifyContent="center">
        <Box id="scanning" className={classes.title}>
          <Typography
            variant="h2"
            style={{
              color: "#F26522",
              textTransform: "uppercase",
              marginBottom: "40px",
            }}
          >
            {processing.ProcessingTitle}
          </Typography>
          <Typography variant="body1" style={{ maxWidth: "660px" }}>
            {processing.ProcessingLead}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.container}>
        {steps.map(step => (
          <Box className={classes.content}>
            <img
              alt={step.title}
              src={step.image}
              className={classes.scanImage}
            />
            <Typography variant="h3" className={classes.processTitle}>
              {step.title}
            </Typography>
            <Typography variant="body1" className={classes.processParagraph}>
              {step.lead}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  )
}
