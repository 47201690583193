import React from "react"

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="96"
      height="96"
      fill="none"
      viewBox="0 0 96 96"
    >
      <circle
        cx="48"
        cy="48"
        r="45.405"
        stroke="#F26522"
        strokeWidth="4"
      ></circle>
      <path
        stroke="#F4F5F6"
        strokeWidth="2.595"
        d="M17.514 54.745h12.064m48.779 0H29.578m0 0v-18.94M48 54.615v-7.913m18.422 7.913v-18.81"
      ></path>
      <mask id="path-3-inside-1" fill="#fff">
        <path
          fillRule="evenodd"
          d="M67.718 35.803l.002.001h-.001L48 47.998 28.281 35.804h.001l19.719-12.195 19.717 12.194zm-4.932 0L48.001 26.66l-14.786 9.144L48 44.948l14.786-9.144z"
          clipRule="evenodd"
        ></path>
      </mask>
      <path
        fill="#F26522"
        fillRule="evenodd"
        d="M67.718 35.803l.002.001h-.001L48 47.998 28.281 35.804h.001l19.719-12.195 19.717 12.194zm-4.932 0L48.001 26.66l-14.786 9.144L48 44.948l14.786-9.144z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#F4F5F6"
        d="M67.718 35.803l-1.364 2.207 1.364-2.207zm.002.001v2.595h9.132l-7.768-4.802-1.364 2.207zm-.001 0v-2.595h-.738l-.627.388 1.365 2.207zM48 47.998l-1.364 2.207L48 51.05l1.365-.844L48 47.998zM28.281 35.804v-2.595h-9.128l7.764 4.801 1.364-2.206zm.001 0v2.594h.738l.627-.388-1.365-2.206zm19.719-12.195l1.364-2.206-1.364-.844-1.365.844 1.365 2.206zm0 3.051l1.364-2.207-1.364-.844-1.365.844 1.365 2.207zm14.785 9.144l1.365 2.206 3.568-2.206-3.568-2.207-1.365 2.207zm-29.571 0l-1.365-2.207-3.568 2.207 3.568 2.207 1.365-2.207zM48 44.948l-1.364 2.206 1.364.844 1.365-.843L48 44.947zm21.084-11.351l-.002-.001-2.728 4.414.002.001 2.728-4.414zm-1.365 4.802v-5.19 5.19zm-39.437-5.19v5.19-5.19zm18.354-4.342l14.786 9.143 2.729-4.413-14.785-9.144-2.73 4.414zM34.58 38.01l14.786-9.144-2.729-4.414-14.786 9.144 2.73 4.414zm14.786 4.73l-14.786-9.144-2.729 4.414 14.785 9.143 2.73-4.413zm12.057-9.144l-14.786 9.144 2.729 4.413L64.15 38.01l-2.73-4.413zm7.66 0L49.367 21.402l-2.73 4.413L66.354 38.01l2.729-4.414zM49.366 50.204l19.718-12.194-2.729-4.414-19.718 12.195 2.729 4.413zM26.917 38.011l19.718 12.194 2.73-4.413-19.72-12.195-2.728 4.413zm19.72-16.608l-19.72 12.194 2.73 4.413 19.718-12.194-2.729-4.413z"
        mask="url(#path-3-inside-1)"
      ></path>
      <path
        stroke="#F4F5F6"
        strokeDasharray="6.23 4.15"
        strokeMiterlimit="3.864"
        strokeWidth="2.595"
        d="M29.58 54.746h36.842v4.41L48.001 71.223 29.579 59.157v-4.41z"
      ></path>
    </svg>
  )
}

export default Icon
