import { Typography, Box, makeStyles } from "@material-ui/core"
import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"

const useStyles = makeStyles(theme => ({
  container: {
    maxHeight: "592px",
    marginTop: "128px",
    position: "relative",
    minHeight: "500px",
    [theme.breakpoints.down("md")]: {
      marginTop: "32px",
    },
  },
  bgimage: {
    minHeight: "500px",
    maxHeight: "592px",
    objectFit: "cover",
    filter: "brightness(0.5)",
  },
  content: {
    paddingLeft: "30%",
    paddingRight: "16px",
    [theme.breakpoints.down("lg")]: {
      paddingLeft: "10%",
      marginLeft: "5%",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "16px",
      marginLeft: "0",
    },
    top: "25%",
    left: "0px",
    position: "absolute",
    height: "100%",
  },
  button: {
    boxShadow: "none",
    textDecoration: "none",
    color: "#010000",
    fontSize: "16px",
    textTransform: "uppercase",
    fontWeight: "700",
    paddingLeft: "22.5px",
    paddingRight: "22.5px",
    paddingTop: "15.5px",
    paddingBottom: "15.5px",
    backgroundColor: "#F26522",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "#FF6a24",
    },
    "&:active": {
      boxShadow: "none",
    },
  },
}))

export const GetInTouch = ({ create, background }) => {
  const classes = useStyles()
  return (
    <Box className={classes.container}>
      <Img
        className={classes.bgimage}
        fluid={
          background
            ? background
            : create.CreateBg.imageFile.childImageSharp.fluid
        }
      />
      <Box className={classes.content}>
        <Typography
          variant="h1"
          style={{
            marginBottom: "32px",
            maxWidth: "500px",
            filter: "drop-shadow(0px 0px 4px #000b)",
          }}
        >
          {create.CreateTitle}
        </Typography>
        <Typography
          variant="h3"
          style={{
            marginBottom: "48px",
            maxWidth: "700px",
            fontWeight: "700",
            filter: "drop-shadow(0px 0px 4px #000b)",
          }}
        >
          {create.CreateLead}
        </Typography>
        <Link variant="contained" to="/contact" className={classes.button}>
          {create.CreateButton}
        </Link>
      </Box>
    </Box>
  )
}
