import { CssBaseline } from "@material-ui/core"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { Footer } from "./footer"
import { Menu } from "./menu"
import { Navbar } from "../navbar/navbar"
import { graphql, useStaticQuery } from "gatsby"
import SEO from "./seo"

const LayoutServices = ({ children, mainPage }) => {
  const [isMenuOpen, setMenuOpen] = useState(false)

  const data = useStaticQuery(graphql`
    {
      strapi {
        servicesHomePage {
          ServicesHomeLogo {
            url
          }
        }
      }
    }
  `)

  const menuLinks = [
    { name: "Home", link: "/services" },
    { name: "Motion capture", link: "/services/mocap" },
    { name: "Photo scan", link: "/services/photo-scan" },
  ]

  return (
    <main>
      <CssBaseline />
      <SEO title="DIGIC Services" />
      <Navbar
        setMenuOpen={setMenuOpen}
        logo={data.strapi.servicesHomePage.ServicesHomeLogo.url}
        to="/services"
      />
      {children}
      <Menu
        isOpen={isMenuOpen}
        close={() => setMenuOpen(false)}
        menuLinks={menuLinks}
      />
      <Footer />
    </main>
  )
}

LayoutServices.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutServices
