import React from "react"

function Down() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="8"
      fill="none"
      viewBox="0 0 12 8"
    >
      <path
        fill="#F26522"
        d="M6.006 7.4c-.22 0-.42-.08-.58-.24l-5-5.2c-.3-.32-.3-.82.02-1.14.32-.3.82-.3 1.14.02l4.42 4.6 4.42-4.6c.3-.32.82-.32 1.14-.02.32.3.32.82.02 1.14l-5 5.2c-.16.16-.36.24-.58.24z"
      ></path>
    </svg>
  )
}

export default Down
