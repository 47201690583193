import { Box, makeStyles, Typography } from "@material-ui/core"
import Img from "gatsby-image"
import React from "react"
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider"
import ReactMarkdown from "react-markdown"
import RemarkGfmPlugin from "remark-gfm"

const useStyles = makeStyles(theme => ({
  container: {
    margin: "32px auto",
    padding: "0 24px",
    maxWidth: "1440px",
    [theme.breakpoints.down("md")]: {
      padding: "64px 24px",
    },
  },
  title: {
    display: "flex",
    paddingBottom: "16px",
    width: "100%",
    paddingLeft: "0px",
    [theme.breakpoints.down("md")]: {
      paddingTop: "16px",
      paddingBottom: "16px",
    },
  },
  serviceContainer: {
    justifyContent: "flex-start",
    marginLeft: "auto",
    marginRight: "auto",
    position: "relative",
    width: "100%",
    maxWidth: "1440px",
    display: "flex",
    alignItems: "start",
    gap: "64px",
    marginBottom: "96px",
    [theme.breakpoints.down("md")]: {
      margin: "0",
      flexDirection: "column-reverse",
      position: "initial",
      height: "initial",
    },
  },
  textContainer: {
    margin: "0",
    color: "#F4F5F6",
    display: "flex",
    alignItems: "start",
    justifyContent: "start",
    minHeight: "300px",
    width: "100%",
    maxWidth: "800px",
    fontFamily: "Verdana",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "17px",
    lineHeight: "1.6",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      margin: "0",
      minHeight: "initial",
      width: "initial",
    },
  },
  line: {
    fontSize: "16px",
    color: "#F4F5F6",
    paddingLeft: "0px",
  },
  rightImage: {
    width: "100%",
    height: "100%",
  },
}))

export const ServicesScanScanning = ({ scanning }) => {
  const classes = useStyles()
  return (
    <Box className={classes.container}>
      <Box width="100%" display="flex" justifyContent="center">
        <Box id="scanning" className={classes.title}>
          <Typography variant="h1">{scanning.ScanningTitle}</Typography>
        </Box>
      </Box>
      <Box
        className={classes.serviceContainer}
        id="mocap-services"
        marginBottom={8}
      >
        <ReactCompareSlider
          itemOne={
            <ReactCompareSliderImage
              src={scanning.ScanningLeftImageModel.imageFile.publicURL}
              alt="Image one"
            />
          }
          itemTwo={
            <ReactCompareSliderImage
              src={scanning.ScanningLeftImageFinished.imageFile.publicURL}
              alt="Image two"
            />
          }
        />
        <Box className={classes.textContainer} variant="body1">
          <ReactMarkdown
            remarkPlugins={[RemarkGfmPlugin]}
            children={scanning.ScanningDescriptionRight}
            components={{
              h1: ({ node, ...props }) => (
                <Typography
                  variant="h1"
                  style={{
                    marginTop: "24px",
                    marginBottom: "8px",
                  }}
                  {...props}
                />
              ),
              h2: ({ node, ...props }) => (
                <Typography
                  variant="h2"
                  style={{
                    marginTop: "24px",
                    marginBottom: "8px",
                  }}
                  {...props}
                />
              ),
              h3: ({ node, ...props }) => (
                <Typography
                  variant="h3"
                  style={{
                    color: "#F26522",
                    marginTop: "24px",
                    marginBottom: "8px",
                  }}
                  {...props}
                />
              ),
              p: ({ node, ...props }) => (
                <Typography variant="body1" {...props} />
              ),
              a: ({ node, ...props }) => (
                <a
                  style={{ color: "#F26522", lineBreak: "anywhere" }}
                  {...props}
                />
              ),
            }}
          />
        </Box>
      </Box>
      <Box
        style={{ justifyContent: "flex-start" }}
        className={classes.serviceContainer}
        id="mocap-services"
        marginBottom={8}
      >
        <Box className={classes.textContainer} variant="body1">
          <ReactMarkdown
            remarkPlugins={[RemarkGfmPlugin]}
            children={scanning.ScanningDescriptionLeft}
            components={{
              h1: ({ node, ...props }) => (
                <Typography
                  variant="h1"
                  style={{
                    marginTop: "24px",
                    marginBottom: "8px",
                  }}
                  {...props}
                />
              ),
              h2: ({ node, ...props }) => (
                <Typography
                  variant="h2"
                  style={{
                    marginTop: "24px",
                    marginBottom: "8px",
                  }}
                  {...props}
                />
              ),
              h3: ({ node, ...props }) => (
                <Typography
                  variant="h3"
                  style={{
                    color: "#F26522",
                    marginTop: "24px",
                    marginBottom: "8px",
                  }}
                  {...props}
                />
              ),
              p: ({ node, ...props }) => (
                <Typography variant="body1" {...props} />
              ),
              a: ({ node, ...props }) => (
                <a
                  style={{ color: "#F26522", lineBreak: "anywhere" }}
                  {...props}
                />
              ),
            }}
          />
        </Box>
        <ReactCompareSlider
          itemOne={
            <ReactCompareSliderImage
              src={scanning.ScanningRightImageModel.imageFile.publicURL}
              alt="Image one"
            />
          }
          itemTwo={
            <ReactCompareSliderImage
              src={scanning.ScanningRightImageFinished.imageFile.publicURL}
              alt="Image two"
            />
          }
        />
      </Box>
      <Box paddingY="96px">
        <Box className={classes.serviceContainer} id="mocap-services">
          <Img
            className={classes.rightImage}
            fluid={scanning.LocationLeftImage.imageFile.childImageSharp.fluid}
          />
          <Box className={classes.textContainer}>
            <Box id="scanning" className={classes.title}>
              <Typography variant="h1">{scanning.LocationTitle}</Typography>
            </Box>
            <Typography variant="body1" className={classes.line}>
              {scanning.LocationDescriptionListFirst}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
